import './App.css';
import React, { Component } from 'react';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK, { CoinbaseWalletProvider } from "@coinbase/wallet-sdk";
import miners from './assets/miners.gif';
import './sky.css';
import { SocialIcon } from 'react-social-icons';

var Scroll = require('react-scroll');


var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;


let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let totalSupplyNFT2;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let user_input;
let wMintAmount;
let myTokens = [];
let myTokens2 = [];
let adminWhitelistAddresses = [];
let adminPanel = 0;
let adminWhitelistAddresses2 = [];
let maxTokensToBuy = "";
let testingvalue;
let wlMint;
let FinalResult;
let wlMsg = "Whitelist Mint";
let public_sale_cost;
let presalesale_cost;
let MAX_PER_WALLET;
let public_mint_status = false;
let presale_status = false;
let paused;


const ABI = [
	{
	  "inputs": [
		{
		  "internalType": "string",
		  "name": "_initBaseURI",
		  "type": "string"
		},
		{
		  "internalType": "string",
		  "name": "_initNotRevealedUri",
		  "type": "string"
		}
	  ],
	  "stateMutability": "nonpayable",
	  "type": "constructor"
	},
	{
	  "inputs": [],
	  "name": "ApprovalCallerNotOwnerNorApproved",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "ApprovalQueryForNonexistentToken",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "ApprovalToCurrentOwner",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "ApproveToCaller",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "BalanceQueryForZeroAddress",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "MintToZeroAddress",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "MintZeroQuantity",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "OwnerIndexOutOfBounds",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "OwnerQueryForNonexistentToken",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "TokenIndexOutOfBounds",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "TransferCallerNotOwnerNorApproved",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "TransferFromIncorrectOwner",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "TransferToNonERC721ReceiverImplementer",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "TransferToZeroAddress",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "URIQueryForNonexistentToken",
	  "type": "error"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "approved",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "Approval",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "operator",
		  "type": "address"
		},
		{
		  "indexed": false,
		  "internalType": "bool",
		  "name": "approved",
		  "type": "bool"
		}
	  ],
	  "name": "ApprovalForAll",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "previousOwner",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "newOwner",
		  "type": "address"
		}
	  ],
	  "name": "OwnershipTransferred",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "from",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "to",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "Transfer",
	  "type": "event"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "to",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "approve",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		}
	  ],
	  "name": "balanceOf",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "baseURI",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "getApproved",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "operator",
		  "type": "address"
		}
	  ],
	  "name": "isApprovedForAll",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "max_per_wallet",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "quantity",
		  "type": "uint256"
		}
	  ],
	  "name": "mint",
	  "outputs": [],
	  "stateMutability": "payable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "name",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "notRevealedUri",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "owner",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "ownerOf",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "paused",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "presale_cost",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "presale_status",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "publicSaleCost",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "public_mint_status",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "renounceOwnership",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "revealed",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "from",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "to",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "safeTransferFrom",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "from",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "to",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		},
		{
		  "internalType": "bytes",
		  "name": "_data",
		  "type": "bytes"
		}
	  ],
	  "name": "safeTransferFrom",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "operator",
		  "type": "address"
		},
		{
		  "internalType": "bool",
		  "name": "approved",
		  "type": "bool"
		}
	  ],
	  "name": "setApprovalForAll",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "string",
		  "name": "_newBaseURI",
		  "type": "string"
		}
	  ],
	  "name": "setBaseURI",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_MAX_SUPPLY",
		  "type": "uint256"
		}
	  ],
	  "name": "setMAX_SUPPLY",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_max_per_wallet",
		  "type": "uint256"
		}
	  ],
	  "name": "setMax_per_wallet",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "string",
		  "name": "_notRevealedURI",
		  "type": "string"
		}
	  ],
	  "name": "setNotRevealedURI",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_publicSaleCost",
		  "type": "uint256"
		}
	  ],
	  "name": "setPublicSaleCost",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "setStatus_presale",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_whitelistCost",
		  "type": "uint256"
		}
	  ],
	  "name": "setWhitelistCost",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "newWhitelistSigner",
		  "type": "bytes32"
		}
	  ],
	  "name": "setWhitelistSigner",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_presale_cost",
		  "type": "uint256"
		}
	  ],
	  "name": "set_presale_cost",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_total_PS_limit",
		  "type": "uint256"
		}
	  ],
	  "name": "set_total_PS_limit",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_total_presale_limit",
		  "type": "uint256"
		}
	  ],
	  "name": "set_total_presale_limit",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_total_wl_limit",
		  "type": "uint256"
		}
	  ],
	  "name": "set_total_wl_limit",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes4",
		  "name": "interfaceId",
		  "type": "bytes4"
		}
	  ],
	  "name": "supportsInterface",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "symbol",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "toggleReveal",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "toggle_paused",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "toggle_public_mint_status",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "toggle_wl_mint_status",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "index",
		  "type": "uint256"
		}
	  ],
	  "name": "tokenByIndex",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "index",
		  "type": "uint256"
		}
	  ],
	  "name": "tokenOfOwnerByIndex",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "tokenURI",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "totalSupply",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "total_PS_count",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "total_PS_limit",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "total_presale_count",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "total_presale_limit",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "total_wl_count",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "total_wl_limit",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "from",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "to",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "transferFrom",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "newOwner",
		  "type": "address"
		}
	  ],
	  "name": "transferOwnership",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "whitelistCost",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32[]",
		  "name": "_proof",
		  "type": "bytes32[]"
		},
		{
		  "internalType": "uint256",
		  "name": "quantity",
		  "type": "uint256"
		}
	  ],
	  "name": "whitelistMint",
	  "outputs": [],
	  "stateMutability": "payable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "whitelistSigner",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "withdraw",
	  "outputs": [],
	  "stateMutability": "payable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "wl_mint_status",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	}
  ];

const address = "0xd32E98cC0330B850804F520f23823b88C5B603aF";
let contract;


class App extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: '1',
		totalValue: "",
		presaleValue: "0",
		maxmint: '',
		_adminPanel: 0,
		_adminWhitelistAddresses: [],
		_adminWhitelistAddresses2: [],
		_maxTokensToBuy: "",
		_testingValue: '',
		_wlMint: '',
		_FinalResult: '',
		_wlMsg: 'Whitelist Mint',
		_public_sale_cost: '',
		_presalesale_cost: '',
		_MAX_PER_WALLET: '',
		_public_mint_status: '',
		_presale_status: '',
		_cost: '',
		_mintStatus: false,
		_paused: false,
	}

	onSubmitMinus = async event => {
		event.preventDefault();

		mintAmount = mintAmount - 1;

		if (mintAmount < 1) {
			mintAmount = 1
		}


		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 10000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;
			wMintAmount = mintAmount;

			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {


			if (totalSupplyNFT < 10000) {

				onlyLeft = 20 - publicSale;

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;


				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmitPlus = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount = mintAmount + 1;

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 10000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;
			wMintAmount = mintAmount;


			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {

			if (totalSupplyNFT < 10000) {

				onlyLeft = 20 - publicSale;
				console.log(onlyLeft);

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;

				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contract.methods.owner().call();


			if (account != owner) {


				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);


					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({gasLimit: 385000,  from: account, value: this.state.totalValue * 1 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}
			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);


					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 385000, from: account, value: this.state.totalValue * 0 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}






			}
		} catch (err) {

			console.log(err);

		}
	}

	walletConnect = async event => {
		event.preventDefault();

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "811915bee3744bd38afcf17ecac0f9a6" // required
				}
			},				
			coinbasewallet: {
				package: CoinbaseWalletSDK, // Required
				options: {
					appName: "Aterium Universe", // Required
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
					rpc: "", // Optional if `infuraId` is provided; otherwise it's required
					chainId: 1, // Optional. It defaults to 1 if not provided
					darkMode: true // Optional. Use dark theme, defaults to false
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
			console.log("I'm LOGGED IN");
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("web3Modal.cachedProvider :" + web3Modal.cachedProvider);
			console.log("provider :" + provider);

			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);

			if (provider) {


			//	(async () => {

					if (web3Modal.cachedProvider != "walletconnect" && web3Modal.cachedProvider != "coinbasewallet")  {

						const chainId = 1;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://mainnet.infura.io/v3/'],

												/*chainName: 'Rinkeby Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'RinkebyETH', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://rinkeby.infura.io/v3/'],*/
											},
										],
									});
								}
							}
						}

						

						try {

							paused = await contract.methods.paused().call();
							this.setState({ _paused: paused });

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							public_sale_cost = await contract.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);

							presalesale_cost = await contract.methods.presale_cost().call();
							this.setState({ _presalesale_cost: presalesale_cost });
							console.log("presalesale_cost :" + presalesale_cost);

							MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
							console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

							presale_status = await contract.methods.presale_status().call();
							this.setState({ _presale_status: presale_status });
							console.log("presale_status :" + presale_status);

							public_mint_status = await contract.methods.public_mint_status().call();
							this.setState({ _public_mint_status: public_mint_status });
							console.log("public_mint_status :" + public_mint_status);

							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);

							if (!paused) {

								if (presale_status) {
									this.setState({ _cost: presalesale_cost });
									this.setState({ _mintStatus: true });

								} else if (public_mint_status) {
									this.setState({ _cost: public_mint_status });
									this.setState({ _mintStatus: false });

								} else {
									this.setState({ _cost: 0 });
									this.setState({ _mintStatus: false });

								}

							}

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 10000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;
								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 10000) {

									onlyLeft = 10000 - totalSupplyNFT;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = 20 - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}


						} catch (err) {
							console.log("err: " + err);

						}
					} else if(web3Modal.cachedProvider == "walletconnect") {

						const chainId = 1;

						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://mainnet.infura.io/v3/'],
												
												/*chainName: 'Rinkeby Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'RinkebyETH', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://rinkeby.infura.io/v3/'],*/
											},
										],
									});
								}
							}
						}


						try {

							paused = await contract.methods.paused().call();
							this.setState({ _paused: paused });

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							public_sale_cost = await contract.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);

							presalesale_cost = await contract.methods.presale_cost().call();
							this.setState({ _presalesale_cost: presalesale_cost });
							console.log("presalesale_cost :" + presalesale_cost);

							MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
							console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

							presale_status = await contract.methods.presale_status().call();
							this.setState({ _presale_status: presale_status });
							console.log("presale_status :" + presale_status);

							public_mint_status = await contract.methods.public_mint_status().call();
							this.setState({ _public_mint_status: public_mint_status });
							console.log("public_mint_status :" + public_mint_status);

							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);

							if (!paused) {

								if (presale_status) {
									this.setState({ _cost: presalesale_cost });
									this.setState({ _mintStatus: true });

								} else if (public_mint_status) {
									this.setState({ _cost: public_mint_status });
									this.setState({ _mintStatus: false });

								} else {
									this.setState({ _cost: 0 });
									this.setState({ _mintStatus: false });

								}

							}

							

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 10000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;

								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 10000) {

									onlyLeft = 0;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = 20 - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}


						} catch (err) {
							console.log("err: " + err);

						}
					}else if( web3Modal.cachedProvider == "coinbasewallet" ) {

						const chainId = 1;

						if (CoinbaseWalletProvider.networkVersion !== chainId) {
							try {
								await CoinbaseWalletProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await CoinbaseWalletProvider.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://mainnet.infura.io/v3/'],
											},
										],
									});
								}
							}
						}
	
					

					try {

						paused = await contract.methods.paused().call();
						this.setState({ _paused: paused });

						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });
						console.log("Total Supply:" + totalSupplyNFT);

						publicSale = await contract.methods.balanceOf(account).call();
						this.setState({ myNFTWallet: publicSale });

						public_sale_cost = await contract.methods.publicSaleCost().call();
						this.setState({ _public_sale_cost: public_sale_cost });
						console.log("public_sale_cost :" + public_sale_cost);

						presalesale_cost = await contract.methods.presale_cost().call();
						this.setState({ _presalesale_cost: presalesale_cost });
						console.log("presalesale_cost :" + presalesale_cost);

						MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
						this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
						console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

						presale_status = await contract.methods.presale_status().call();
						this.setState({ _presale_status: presale_status });
						console.log("presale_status :" + presale_status);

						public_mint_status = await contract.methods.public_mint_status().call();
						this.setState({ _public_mint_status: public_mint_status });
						console.log("public_mint_status :" + public_mint_status);

						owner = await contract.methods.owner().call();
						console.log("Owner" + owner);

						if (!paused) {

							if (presale_status) {
								this.setState({ _cost: presalesale_cost });
								this.setState({ _mintStatus: true });

							} else if (public_mint_status) {
								this.setState({ _cost: public_mint_status });
								this.setState({ _mintStatus: false });

							} else {
								this.setState({ _cost: 0 });
								this.setState({ _mintStatus: false });

							}

						}

						if (owner == account) {
							console.log("owner : " + owner)
							onlyLeft = 10000 - totalSupplyNFT;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}

							valueOfNFTs = mintAmount * 0;
							wMintAmount = mintAmount;


							this.setState({ nftMintingAmount: mintAmount });

							this.setState({ totalValue: valueOfNFTs });
						} else {
							mintAmount = 1;

							if (totalSupplyNFT == 10000) {

								onlyLeft = 10000 - totalSupplyNFT;
								mintAmount = onlyLeft;
								this.setState({ msg: "SOLD OUT" });

							} else {
								mintAmount = 1;
								onlyLeft = 20 - publicSale;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}
								//mintAmount = onlyLeft;

								valueOfNFTs = mintAmount * this.state._cost;
								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							}
						}


					} catch (err) {
						console.log("err: " + err);

					}


				}


				//})();

				//.....................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}

		}

	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "9aad89c8e515457ab8b7805f5da593ea" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload();

	}
	render() {

		return (

			<div class="allWrap">
				<div class="light">

					<div class="headers">

						<div class="headers2">

							<div class="navBar">

							</div>
							<div class="right">

								<div class="discord">
									<SocialIcon url="https://worlddataminers.com" label="Our portfolio" target="_blank" bgColor="#ffffff" style={{ height: 40, width: 40 }} />
								</div>

								<div class="discord2">
									<SocialIcon url="https://worlddataminers.com" label="Our portfolio" target="_blank" bgColor="#ffffff" style={{ height: 70, width: 70 }} />
								</div>

								(<div class="connect2">
									{this.state.walletAddress === '' ?
										(<form onSubmit={this.walletConnect}>
											<button class="wallet2" >Wallet Connect</button>
										</form>) : (<form onSubmit={this.walletDisconnect}><button class="wallet2" >
											{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

								</div>)



							</div>

						</div>

					</div>

					<div class="introduction">

						<div class="in2">
							<div class="intro">
								World Data Miners
							</div>

							<div class="intro2">The WDM NFT Collection consist of 10,000 unique, hand drawn Miners from across space and time, spanning Earth years 1977 to 3077. They are immortalized forever on the Ethereum Blockchain.</div>
							<Element name="mint">
								<div class="nftblockWalletConnectedALL">

									{this.state.walletAddress === '' ? (

										<div class="walletConnect">
											<form onSubmit={this.walletConnect}>
												<button class="wallet3" type='submit'>MINT NOW</button>
											</form>
										</div>
									) : null

									}
								</div>
							</Element>
						</div>
						{this.state.walletAddress === '' ?
							(<div class="beastDiv">
								<img class="beast" src={miners} />
							</div>) : (

								(<div class="mintDiv">
									<div class="totalSupply">{this.state.totalSupply}/ 10 000</div>
									{this.state._mintStatus === true ?
									(<div class="price"><div>Presale Mint {this.state._presalesale_cost/1000000000000000000} ETH</div></div>):
									(<div class="price"><div>Public Mint {this.state._public_sale_cost/1000000000000000000} ETH</div></div>)}


									<div class="minting_count_button">
										<div class="center">
											<form onSubmit={this.onSubmitMinus}>
												<button class="btnfos-0-2" type="submit">-</button>
											</form>
										</div>

										<div>
											<div class="nftminter2">{this.state.nftMintingAmount}</div>
										</div>


										<div class="center">
											<form onSubmit={this.onSubmitPlus}>
												<button class="btnfos-0-2-2" type="submit">+</button>
											</form>
										</div>
									</div>

									<div class="mintbuttondiv">
										{this.state._mintStatus === true ? (
											<div>

												<form onSubmit={this.onSubmit2}>
													<button class="btnfos-0-3" type="submit">
														Presale Mint</button>
												</form>

											</div>
										) : (<form onSubmit={this.onSubmit2}>
											<button class="btnfos-0-3" type="submit">
												Public Mint</button>
										</form>)}
									</div>
									<div>

										{this.state.statusError ? (
											<div class="errorMessage">
												<div >Sorry, something went wrong please try again later</div>
											</div>)
											: null}

										{this.state.statusLoading ? (
											<div class="loadingContainer">
												<div>
													<div class="loadingText">Minting your Miners</div>
												</div>
											</div>)
											: null}

										{this.state.success ? (

											<div><div class="successfully">🎉 MINTING SUCCESSFUL! 🎉</div>
											</div>)
											: null}

									</div></div>

								)
							)}
					</div>

				</div >
			</div >)
	}
}

export default App;
